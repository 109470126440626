import { createHashRouter, Navigate } from "react-router-dom";
import { AccountPage } from "./account";
import { Games } from "./games";
import HomePage from "./home";
import Layout from "./layout";
import { Leaderboard } from "./leaderboard";
import { LoginPage } from "./login";
import { RegistrationPage } from "./registration";

export const router = createHashRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Navigate to="/" />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/registration",
        element: <RegistrationPage />,
      },
      {
        path: "/leaderboard",
        element: <Leaderboard />,
      },
      {
        path: "/games",
        element: <Games />,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/account",
        element: <AccountPage />,
      },
    ],
  },
]);

import { FaPencilAlt } from "react-icons/fa";
import { useUser } from "../../context";

// import { makeUser } from "../../firebase/user";

function toTitleCase(str: string): string {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}

export function AccountPage() {
  const user = useUser();

  if (user === undefined) {
    return (
      <div>
        Hmm, you're not logged in and we haven't quite set up the registration
        page yet. Sorry!
      </div>
    );
  } else {
    return (
      <div style={{ padding: "10px", fontFamily: "Arial, sans-serif" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            fontSize: "14px",
          }}
        >
          <span>{toTitleCase("aspiring sausage " + user.victoryTitle)}</span>
          <FaPencilAlt style={{ scale: "85%", marginLeft: "6px" }} />
        </div>
        <div
          style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "5px" }}
        >
          {user.fullName}
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "6px",
            fontSize: "15px",
          }}
        >
          <span>Email: {user.email}</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "6px",
            fontSize: "15px",
          }}
        >
          <span>Phone: {user.phoneNumber}</span>
          <FaPencilAlt style={{ scale: "85%", marginLeft: "6px" }} />
        </div>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "6px",
            fontSize: "15px",
          }}
        >
          <span>
            {user.dietaryRestrictions.length > 0
              ? user.dietaryRestrictions
              : "No dietary restrictions"}
          </span>
          <FaPencilAlt style={{ scale: "85%", marginLeft: "6px" }} />
        </div>
      </div>
    );
  }
}

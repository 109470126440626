import { createContext, useContext } from "react";
import User from "./firebase/data/user";

interface GlobalContext {
  user?: User;
}

export const useGlobalContext = (): GlobalContext => {
  return useContext(userContext);
};

export const useUser = (): User | undefined => {
  return useGlobalContext().user;
};

export const userContext = createContext<GlobalContext>({});

import {
  GoogleAuthProvider,
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { useState } from "react";
import { UserData } from "./data/user";
import { auth } from "./firebase";
import users from "./user";

const useAuthUid = (): string | null => {
  const [userUid, setUserUid] = useState<string | null>(null);
  onAuthStateChanged(auth, (user) => {
    if (user) {
      setUserUid(user.uid);
    } else {
      setUserUid(null);
    }
  });
  return userUid;
};

const createUser = (
  email: string,
  password: string,
  userData: UserData,
  onSuccess: (user: User) => void,
  onFailure: (error: any) => void
) => {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed up
      const user = userCredential.user;
      console.log("Account creation successful");
      setDoc(doc(users, user.uid), { ...userData, email });
      onSuccess(user);
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log("Account creation failure", errorCode, errorMessage);
      onFailure(error);
    });
};

const signInEmail = (
  email: string,
  password: string,
  onSuccess: (user: User) => void,
  onFailure: (error: any) => void
) => {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed up
      const user = userCredential.user;
      onSuccess(user);
    })
    .catch((error) => {
      //   const errorCode = error.code;
      //   const errorMessage = error.message;
      onFailure(error);
    });
};

const signInGoogle = (
  onSuccess: (user: User) => void,
  onFailure: (error: any) => void
) => {
  const provider = new GoogleAuthProvider();
  signInWithPopup(auth, provider)
    .then((result) => {
      const user = result.user;
      onSuccess(user);
    })
    .catch((error) => {
      onFailure(error);
    });
};

const signOut = () => {
  auth.signOut();
};

export { createUser, signInEmail, signInGoogle, signOut, useAuthUid };

import { collection, getDocs } from "firebase/firestore";
import Mutex from "ts-mutex";
import { AllGames, Games } from "./data/games";
import { firestore } from "./firebase";

var _allgames: AllGames = new Map();
const _lock = new Mutex();

export const getGamesForAllYears = async (): Promise<AllGames> => {
  await _lock.use(async () => {
    if (Object.keys(_allgames).length > 0) return;
    const querySnapshot = await getDocs(collection(firestore, "games"));
    querySnapshot.forEach((doc) => {
      _allgames.set(parseInt(doc.id), doc.data()!.games as Games);
    });
  });
  return _allgames;
};

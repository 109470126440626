import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useHref, useLocation } from "react-router-dom";
import { useUser } from "../context";

const SfNavbar: React.FC = () => {
  const user = useUser();
  const homeLink = useHref("/");
  const [closeRegister, setCloseRegister] = useState(false);

  const registration = useHref("/registration");
  const loc = useLocation();
  return (
    <>
      {!closeRegister &&
      user !== undefined &&
      user.registrations["2024"] === undefined ? (
        <div className="banner">
          <span>
            <a href={registration}>Register now </a>for SF 2024!
          </span>
          <FontAwesomeIcon
            icon={faTimes}
            className="close-icon"
            onClick={() => setCloseRegister(true)}
          />
        </div>
      ) : null}
      <Navbar collapseOnSelect bg="dark" expand="sm">
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Brand href={homeLink}>
            <img
              src="/sausage_fest_small.png"
              alt="Sausagefest!"
              width={64}
              height={64}
              style={{ marginTop: -16, marginBottom: -16 }}
            />
          </Navbar.Brand>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto" activeKey={loc.pathname}>
              <NavLink path="/">Home</NavLink>
              <NavLink path="/games">Games</NavLink>
              <NavLink path="/leaderboard">Scores</NavLink>
              <NavLink path="/account">Account</NavLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

function NavLink({ children, path }: { children: string; path: string }) {
  const href = useHref(path);
  return (
    <Nav.Link href={href} eventKey={path}>
      {children}
    </Nav.Link>
  );
}

export default SfNavbar;

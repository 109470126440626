import { useState } from "react";
import { Button, Container, Form, FormGroup } from "react-bootstrap";
// import { makeUser } from "../../firebase/user";

export function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit() {
    console.log(email, password);
    // makeUser(email, password).then((result) => {
    //   console.log("HEYYYYYY", result);
    // });
  }

  return (
    <Container>
      <Form noValidate onSubmit={handleSubmit}>
        <FormGroup>
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="text"
            placeholder="Brawtford Wurstington"
            name="email"
            required
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            name="password"
            required
            onChange={(event) => {
              setPassword(event.target.value);
            }}
          />
        </FormGroup>
        <Button type="submit">Let's Sausage it up!</Button>
      </Form>
    </Container>
  );
}

import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";

export function RainConfetti() {
  const { width, height } = useWindowSize();
  console.log("Confetto!");
  return (
    <Confetti
      width={width}
      height={height}
      // turn to true to have it run forever
      recycle={false}
    />
  );
}

export function RainSausage() {
  const { width, height } = useWindowSize();
  console.log("Confetto!");
  return (
    <Confetti
      width={width}
      height={height}
      // turn to true to have it run forever
      recycle={false}
      // kinda ugly
      drawShape={(ctx) => {
        ctx.beginPath();
        ctx.moveTo(-20, -5); // Start from the left end
        ctx.quadraticCurveTo(0, -15, 20, -5); // Draw the top curve
        ctx.lineTo(20, 5); // Draw the right side
        ctx.quadraticCurveTo(0, 15, -20, 5); // Draw the bottom curve
        ctx.closePath();
        ctx.fillStyle = "#ff6347"; // Tomato color
        ctx.fill();
      }}
    />
  );
}

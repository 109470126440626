import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  Unsubscribe,
} from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { useAuthUid } from "./auth";
import User from "./data/user";
import { auth, firestore } from "./firebase";

const users = collection(firestore, "users");

const getUser = async (uid: string) => {
  return ((await getDoc(doc(users, uid)))?.data() as User) ?? undefined;
};

const useUserInternal = (): User | undefined => {
  const [user, setUser] = useState<User | undefined>(undefined);
  const userUid = useAuthUid();
  useEffect(() => {
    if (userUid) {
      getUser(userUid).then((user) => {
        setUser(user);
      });
    } else {
      setUser(undefined);
    }
  }, [userUid]);
  return user;
};

const snapshotUser = (uid: string, onUpdate: (user: User) => void) => {
  return onSnapshot(doc(users, uid), (snapshot) => {
    onUpdate(snapshot.data() as User);
  });
};

const snapshotCurrentUser = (
  onUpdate: (user: User) => void,
  updateUnsubscribe: (unsubscribe: Unsubscribe) => void,
  onLogout?: () => void
) => {
  auth.onAuthStateChanged((user) => {
    if (user) {
      updateUnsubscribe(snapshotUser(user.uid, onUpdate));
    } else {
      onLogout?.();
      console.log("User Signed Out");
    }
  });
};

const withUser =
  <P,>(Child: FC<P>) =>
  (props: P) => {
    const [user, setUser] = useState(undefined as User | undefined);
    const [unsubscribe, setUnsubscribe] = useState(() => () => {});
    useEffect(() => {
      unsubscribe();
      snapshotCurrentUser(
        (user) => setUser(user),
        (unsub) => setUnsubscribe(() => () => unsub())
      );
    }, [unsubscribe]);
    useEffect(() => unsubscribe, [unsubscribe]);

    return <Child {...props} user={user} />;
  };
export default users;
export { getUser, useUserInternal, withUser };

export default function About() {
  return (
    <div style={{ maxWidth: "850px" }}>
      SAUSAGEFEST 2024 will take place on <b>Sunday, August 18, 2024.</b>
      <br />
      <br /> The competition will begin at 11am at Ivanhoe Sports Fields in
      Bellevue, WA. Please plan to arrive to the park at 10am to ensure plenty
      of time for check in. RSVP by WEDNESDAY 7/31 to ensure that you don't go
      hungry!
      <br />
      <br />
      <br />
      Details
      <br />
      <br />
      When: Sunday August 18, check-in from 10-10:30am. Games begin promptly
      following the ceremony which begins at 10:45am. Late players may incur a
      penalty.
      <br />
      <br />
      Location: Ivanhoe Sports Fields, 16661 Northup Way, Bellevue, WA 98008
      <br />
      <br />
      +1s and their +1s: Please forward this form to anyone who may be
      interested in sausage. Registration via this RSVP is MANDATORY for all
      players.
      <br />
      <br />
      Food: Sausages, buns, and condiments will be provided. If you do bring
      food, please indicate below what you will bring.
      <br />
      <br />
      Drink: Water will be available for hydration purposes.
      <br />
      <br />
      Games: Games will begin promptly at 11am. You will be scored based on your
      performance in the games for a chance to win the PERPETUAL TROPHY OF THE
      SAUSAGE MONARCHY.
      <br />
      <br />
      **NEW THIS YEAR** Suggested Reimbursement of $10-$20 This is our fourth
      annual SAUSAGEFEST, and we are *growing*! Which is exciting, but it is
      also expensive. We do not hold this event for profit. In fact, we lose
      money every year! If you love our event, please consider reimbursing us!
      :-) Please send a minimum of $10 if you would like a shirt Reimbursement
      can be sent to your host, Ethan, via Venmo @Ethan-Seal-1 (last 4 digits
      2492) https://venmo.com/u/Ethan-Seal-1
      <br />
      <br />
      **A NOTE TO LAST YEAR'S SAUSAGE KING** DO NOT forget the PERPETUAL TROPHY
      OF THE SAUSAGE MONARCHY. It must arrive in pristine condition, otherwise
      you may be publicly shamed.
    </div>
  );
}

import { useState } from "react";
import { AllGames, Game } from "../../firebase/data/games";
import { getGamesForAllYears } from "../../firebase/game";
import "../styles/leaderboard.css";

export function Games() {
  const [allGames, setAllGames] = useState<AllGames>(new Map());
  const [selectedYear, setSelectedYear] = useState<number | undefined>();

  if (Object.keys(allGames).length === 0) {
    getGamesForAllYears().then((allGames) => {
      setAllGames(allGames);
      setSelectedYear(Array.from(allGames).at(-1)![0]);
    });
  }

  const handleYearChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedYear(parseInt(event.target.value));
  };

  const renderGame = (game: Game) => {
    console.log(game);
    return (
      <div className="object-display">
        <h2>{game.name}</h2>
        <p>{game.description}</p>
        <ul>
          {game.rules.map((rule, index) => (
            <li key={index}>{rule}</li>
          ))}
        </ul>
        {/* {obj.images && obj.images.length > 0 && <ImageCarousel images={obj.images} />} */}
      </div>
    );
  };

  console.log(allGames, selectedYear, allGames.has(selectedYear ?? -1));
  return (
    <div className="games-container">
      <h4 style={{ padding: "5px", textAlign: "center" }}>
        View previous or current games & rules
      </h4>
      <select
        className="year-selector"
        value={selectedYear}
        onChange={handleYearChange}
      >
        {Array.from(allGames).map(([year, games]) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
      {allGames.has(selectedYear ?? -1) ? (
        <table>
          {Object.values(allGames.get(selectedYear!)!).map(renderGame)}
        </table>
      ) : null}
    </div>
  );
}

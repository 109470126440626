import { useEffect, useState } from "react";
import "../styles/leaderboard.css";

interface Player {
  id: number;
  name: string;
  scores: { [game: string]: number };
}

const mockPlayerData: Player[] = [
  {
    id: 1,
    name: "Mock Player 1",
    scores: {
      "Sausage Sling": 0,
      "Bun Run": 0,
      "Ketchup Kombat": 0,
      "Mustard Madness": 0,
    },
  },
  {
    id: 2,
    name: "Mock Player 2",
    scores: {
      "Sausage Sling": 0,
      "Bun Run": 0,
      "Ketchup Kombat": 0,
      "Mustard Madness": 0,
    },
  },
  {
    id: 3,
    name: "Mock Player 3",
    scores: {
      "Sausage Sling": 0,
      "Bun Run": 0,
      "Ketchup Kombat": 0,
      "Mustard Madness": 0,
    },
  },
];

// Mock function to load players
const genLoadPlayers = async (): Promise<Player[]> => {
  console.log("Loading mock players...");
  return mockPlayerData;
};

const genGameOptions = async (): Promise<string[]> => {
  console.log("Loading mock games...");
  return ["Sausage Sling", "Bun Run", "Ketchup Kombat", "Mustard Madness"];
};

const OVERALL = 'Overall';

export function Leaderboard() {
  const [players, setPlayers] = useState<Player[]>([]);
  const [gameOptions, setGameOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchGames = async () => {
      const loadedGames = await genGameOptions();
      setGameOptions(loadedGames);
    };
    const fetchPlayers = async () => {
      const loadedPlayers = await genLoadPlayers();
      setPlayers(loadedPlayers);
    };

    fetchGames().then(() => fetchPlayers());
  }, []);

  const setScore = (playerId: number, game: string, newScore: number) => {
    setPlayers((prevPlayers) =>
      prevPlayers.map((player) =>
        player.id === playerId
          ? { ...player, scores: { ...player.scores, [game]: newScore } }
          : player
      )
    );
  };

  const handleScoreChange = (playerId: number, game: string, value: string) => {
    const newScore = value === "" ? 0 : parseInt(value, 10);
    setScore(playerId, game, newScore);
  };

  return (
    <div className="leaderboard-container">
      <h1>Leaderboard</h1>
      <table className="leaderboard-table">
        <thead>
          <tr>
            <th>Player</th>
            {[OVERALL, ...gameOptions].map((game) => <th key={game}>{game}</th>)}
          </tr>
        </thead>
        <tbody>
          {players.map((player) => (
            <tr key={player.id}>
              <td>{player.name}</td>
              <td key={OVERALL}>
                {Object.values(player.scores).reduce((acc, score) => acc + score, 0)}
              </td>
              {gameOptions.map((game) => (
                <td key={game}>
                  {/* This should only be editable if use is admin, otherwise just a value */}
                  <input
                    type="number"
                    value={player.scores[game]}
                    onChange={(e) =>
                      handleScoreChange(player.id, game, e.target.value)}
                  />
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

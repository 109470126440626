import { RouterProvider } from "react-router-dom";
import "./App.css";
import { router } from "./components/pages/router";
import { userContext } from "./context";
import { useUserInternal } from "./firebase/user";

function App() {
  const user = useUserInternal();
  return (
    <>
      <userContext.Provider value={{ user }}>
        <RouterProvider router={router} />
      </userContext.Provider>
    </>
  );
}

export default App;

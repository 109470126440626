import { useState } from "react";
import {
  Button,
  Container,
  Form,
  FormCheck,
  FormGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { createUser } from "../../firebase/auth";
import { RainConfetti } from "../confetti";

type TShirtSize =
  | "stillHaveShirt"
  | "xs"
  | "s"
  | "m"
  | "l"
  | "xl"
  | "xxl"
  | "noShirt";

type FormDataKeys =
  | "name"
  | "email"
  | "phone"
  | "dietaryRestrictions"
  | "freshMeat"
  | "shirtSize"
  | "victoryTitle"
  | "waiver";

interface FormData {
  name: string;
  email: string;
  phone: string;
  dietaryRestrictions?: string;
  freshMeat?: "on";
  shirtSize: TShirtSize;
  waiver: "on";
  victoryTitle: "queen" | "king" | "monarch";
}

type FormDataRaw = Partial<FormData>;
/**
 * gotten from chat gpt. Will match:
 *
 * +1 123-456-7890
 * (123) 456-7890
 * 123 456 7890
 * 123.456.7890
 * +91 1234567890
 * 0123-4567890
 */
const phoneRegex =
  /^(?:\+\d{1,3}\s?)?(?:\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

function copyAndRemove<T>(set: Set<T>, element: T) {
  const copy = new Set(set);
  copy.delete(element);
  return copy;
}

function copyAndSet<T>(set: Set<T>, element: T, condition: boolean) {
  const copy = new Set(set);
  if (condition) copy.add(element);
  else copy.delete(element);
  return copy;
}

function getInvalidFields(data: FormDataRaw): Set<FormDataKeys> {
  const badFields: Set<FormDataKeys> = new Set();
  const requiredFields: FormDataKeys[] = [
    "email",
    "name",
    "phone",
    "shirtSize",
    "waiver",
    "victoryTitle",
  ];

  requiredFields.forEach((field) => {
    const valid = !!data[field];
    if (!valid) badFields.add(field);
  });

  const regExFields: [FormDataKeys, RegExp][] = [
    ["phone", phoneRegex],
    ["email", emailRegex],
  ];

  regExFields.forEach((e) => {
    const [field, regExp] = e;
    const valid = regExp.test(data[field] || "");
    if (!valid) badFields.add(field);
  });

  return badFields;
}

export function RegistrationPage() {
  const [isDone, setDone] = useState(false);
  const [badFields, setBadFields] = useState<Set<FormDataKeys>>(new Set());

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form = e.currentTarget;
    const dataRaw = new FormData(form);
    const data = {
      fullName: dataRaw.get("name"),
      email: dataRaw.get("email"),
      phoneNumber: dataRaw.get("phone"),
      victoryTitle: dataRaw.get("victoryTitle"),
      dietaryRestrictions: dataRaw.get("dietaryRestrictions"),
    } as FormDataRaw;

    const badFields = getInvalidFields(data);
    // if (badFields.size > 0) {
    //   console.log(badFields);
    //   setBadFields(badFields);
    //   return false;
    // }

    if (form.checkValidity()) {
      console.log("Sending data to backend", data as FormData);
      try {
        createUser(
          dataRaw.get("email")! as string,
          dataRaw.get("password")! as string,
          data,
          (user) => {
            console.log("YAAAH", user.email);
          },
          (error) => {
            console.log(error);
          }
        );
      } catch (e) {
        console.log("Shit", e);
      }
      setDone(true);
    }
  };
  return (
    <Container>
      {isDone && <RainConfetti />}
      <Form noValidate onSubmit={handleSubmit}>
        <div className="form-list">
          <FormGroup>
            {/* Ideally, we should get name from google login */}
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Brawtford Wurstington"
              name="name"
              required
              isInvalid={badFields.has("name")}
              onChange={(event) => {
                setBadFields(
                  copyAndSet(badFields, "name", event.target.value.length === 0)
                );
              }}
              // onBlur={(event) => setBadFields(copyAndSet(badFields, "name", event.target.da))}
            />
          </FormGroup>
          {/* TODO: prepopulate this with their google email */}
          <FormGroup>
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              placeholder="brawtyfireburningonthedancefloor@saus.age"
              name="email"
              required
              isInvalid={badFields.has("email")}
              onChange={() => {
                setBadFields(copyAndRemove(badFields, "email"));
              }}
              onBlur={(event) => {
                setBadFields(
                  copyAndSet(
                    badFields,
                    "email",
                    !emailRegex.test(event.target.value || "")
                  )
                );
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email: brawtwursts@gmail.com.
            </Form.Control.Feedback>
          </FormGroup>
          <FormGroup>
            <Form.Label>Password</Form.Label>
            <Form.Control type="password" name="password" required />
          </FormGroup>
          <FormGroup>
            <Form.Label>Victory Title</Form.Label>
            <Form.Select
              name="victoryTitle"
              required
              defaultValue={"undefined"}
              isInvalid={badFields.has("victoryTitle")}
            >
              <option disabled value="undefined">
                Please select a title upon your victory
              </option>
              <option value="king">Sausage King</option>
              <option value="queen">Sausage Queen</option>
              <option value="monarch">Sausage Monarch</option>
            </Form.Select>
          </FormGroup>
          <FormGroup>
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              type="text"
              placeholder="(+X) XXX-XXX-XXX"
              name="phone"
              required
              isInvalid={badFields.has("phone")}
              onChange={() => {
                setBadFields(copyAndRemove(badFields, "phone"));
              }}
              onBlur={(event) => {
                setBadFields(
                  copyAndSet(
                    badFields,
                    "phone",
                    !phoneRegex.test(event.target.value || "")
                  )
                );
              }}
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid phone number: (+1) 123-456-7890
            </Form.Control.Feedback>
          </FormGroup>
          <FormGroup>
            <Form.Label>Dietary Restrictions</Form.Label>
            <Form.Control
              type="text"
              placeholder="vegetarian, gluten intolerant, etc..."
              name="dietaryRestrictions"
              isInvalid={badFields.has("dietaryRestrictions")}
            />
          </FormGroup>
          <FormGroup>
            <Form.Check
              name="freshMeat"
              type="switch"
              label="This is my first SAUSAGEFEST"
              defaultChecked={true}
              isInvalid={badFields.has("freshMeat")}
            />
          </FormGroup>
          <FormGroup>
            <Form.Label>T Shirt Size</Form.Label>
            <Form.Select
              name="shirtSize"
              required
              isInvalid={badFields.has("shirtSize")}
            >
              <option value="stillHaveShirt">I still have mine</option>
              <option value="xs">XSmall</option>
              <option value="s">Small</option>
              <option value="m">Medium</option>
              <option value="l">Large</option>
              <option value="xl">XLarge</option>
              <option value="xxl">XXLarge</option>
              <option value="noShirt">I don't want a cool shirt</option>
            </Form.Select>
          </FormGroup>
          <FormGroup>
            <Form.Label>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    By submitting this RSVP, you acknowledge that consuming raw
                    or undercooked meats, poultry, seafood, shellfish, or eggs
                    may increase your risk of foodborne illness.
                  </Tooltip>
                }
              >
                <span>Waiver</span>
              </OverlayTrigger>
            </Form.Label>
            <FormCheck
              type="checkbox"
              label="I have read the waiver"
              name="waiver"
              required
              isInvalid={badFields.has("waiver")}
              onChange={(event) => {
                const copy = new Set(badFields);
                if (event.target.checked) copy.delete("waiver");
                else copy.add("waiver");
                setBadFields(copy);
              }}
            />
            <Form.Control.Feedback type="invalid">
              You must check this box.
            </Form.Control.Feedback>
          </FormGroup>
          <Button type="submit">Let's Sausage it up!</Button>
        </div>
      </Form>
    </Container>
  );
}

import { Outlet } from "react-router";
import "../../App.css";
import SfNavbar from "../SfNavbar";

/**
 * This should render the layout of the website. Do not use App.tsx b/c
 * layout is set up under our router so we can redirect using react
 * router.
 */
export default function Layout() {
  return (
    <>
      <SfNavbar />
      {/* Outlet Will render children elements based on route */}
      <div className="pageContent">
        <Outlet />
      </div>
    </>
  );
}

import "../../App.css";

import logo from "../../logo512.png";
import About from "../About";

export default function HomePage() {
  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div style={{ width: "300px" }}>
          <img id="sausage-logo" src={logo} alt="logo" />
        </div>
      </div>
      <p>
        Greetings, my friends. Soon, we will gather together and consume
        sausages!
      </p>
      <About />
    </div>
  );
}
